export const ImageModes = {
  FLIPPED: "FLIPPED",
  NORMAL: "NORMAL",
}

export const TOGGLE_PANEL_INDEX = "TOGGLE_PANEL_INDEX"
export const FORCE_PANEL_INDEX = "FORCE_PANEL_INDEX"
export const SCROLL_PANEL_INDEX = "SCROLL_PANEL_INDEX"
export const SET_MODAL_PANEL_INDEX = "SET_MODAL_PANEL_INDEX"
export const HAS_OPENED_PANEL = "HAS_OPENED_PANEL"
export const FLIP_IMAGE = "FLIP_IMAGE"
export const UPDATE_IMAGES = "UPDATE_IMAGES"
export const TRACK_SLIDE = "TRACK_SLIDE"
export const SHOW_MINIVIEW = "SHOW_MINIVIEW"
export const FORCE_HIDE_MINIVIEW = "FORCE_HIDE_MINIVIEW"
export const TOGGLE_J3D = "TOGGLE_J3D"
export const TOGGLE_J3D_MODAL = "TOGGLE_J3D_MODAL"
export const TOGGLE_J3D_V2_MODAL = "TOGGLE_J3D_V2_MODAL"
export const TOGGLE_MINIVIEW_TAB = "TOGGLE_MINIVIEW_TAB"
export const LOAD_DEFAULTS = "LOAD_DEFAULTS"
export const LOAD_WIZARD = "LOAD_WIZARD"
export const CLEAR_EDIT_FORM_URL = "CLEAR_EDIT_FORM_URL"
export const SHOW_ERROR = "SHOW_ERROR"
export const ADD_WIZARD_DATA = "ADD_WIZARD_DATA"
export const SET_WIZARD_DATA = "SET_WIZARD_DATA"
export const SET_WIZARD_PREF_ORDER = "SET_WIZARD_PREF_ORDER"
export const SET_EXTRA_WIZARD_PARAMS = "SET_EXTRA_WIZARD_PARAMS"
export const ADD_COUPON = "ADD_COUPON"
export const CLEAR_CHOICES = "CLEAR_CHOICES"
export const UPDATE_CHOICE = "UPDATE_CHOICE"
export const UPDATE_CHOICE_DIRECTLY = "UPDATE_CHOICE_DIRECTLY"
export const REMOVE_CHOICE = "REMOVE_CHOICE"
export const TOGGLE_ADDON_CHOICE = "TOGGLE_ADDON_CHOICE"
export const CLEAR_DIAMOND_TMP_DATA = "CLEAR_DIAMOND_TMP_DATA"
export const UPDATE_PRICING = "UPDATE_PRICING"
export const UPDATE_SHIPPING = "UPDATE_SHIPPING"
export const UPDATE_SHIP_EST_VIEW = "UPDATE_SHIP_EST_VIEW"
export const CLEAR_DEFAULT_ENGRAVINGS = "CLEAR_DEFAULT_ENGRAVINGS"
export const CLEAR_ENGRAVING_POS = "CLEAR_ENGRAVING_POS"
export const SHOW_SHARE_EMAIL_MODAL = "SHOW_SHARE_EMAIL_MODAL"
export const ADD_TO_CART = "ADD_TO_CART"
export const CANCEL_ADD_TO_CART = "CANCEL_ADD_TO_CART"
export const UPLOAD_USER_IMAGE = "UPLOAD_USER_IMAGE"
export const CROP_UPLOAD_USER_IMAGE = "CROP_UPLOAD_USER_IMAGE"
export const EDIT_USER_IMAGE = "EDIT_USER_IMAGE"
export const GET_SHIP_ESTIMATE = "GET_SHIP_ESTIMATE"
export const UPDATE_PRODUCT_SPECS = "UPDATE_PRODUCT_SPECS"
export const UPDATE_DISPLAY_STATE = "UPDATE_DISPLAY_STATE"
export const UPDATE_INVENTORY = "UPDATE_INVENTORY"
export const FETCH_INVENTORY = "FETCH_INVENTORY"
export const FETCH_WARRANTY_OFFERS = "FETCH_WARRANTY_OFFERS"
export const FETCH_STYLECODE = "FETCH_STYLECODE"
export const CHECK_AVAILABILITY = "CHECK_AVAILABILITY"

export const SET_BUILDER_ITEMS = "SET_BUILDER_ITEMS"
export const ADD_BUILDER_ITEM = "ADD_BUILDER_ITEM"
export const REMOVE_BUILDER_ITEM = "REMOVE_BUILDER_ITEM"
export const MOVE_BUILDER_ITEM = "MOVE_BUILDER_ITEM"
export const FINISH_ADD_BUILDER_ITEM = "FINISH_ADD_BUILDER_ITEM"
export const SET_EXTRA_WIZARD_PREF_ORDER = "SET_EXTRA_WIZARD_PREF_ORDER"

export const PHOTO_BOX_INITIAL_VIEW = "PHOTO_BOX_INITIAL_VIEW"
export const REPLACE_WIZARD_DATA = "REPLACE_WIZARD_DATA"
export const SET_WIZARD_LOADING = "SET_WIZARD_LOADING"
export const CHECK_IF_IN_WISHLIST = "CHECK_IF_IN_WISHLIST"
export const PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND"
export const PRODUCT_FOUND = "PRODUCT_FOUND"
export const SET_WIZARD_HEADER = "SET_WIZARD_HEADER"

export const CALCULATE_AS_LOW_AS = "CALCULATE_AS_LOW_AS"
export const RESET_PANE_FILTERS = "RESET_PANE_FILTERS"
export const SET_BOX_DESIGNS = "SET_BOX_DESIGNS"

export const ADD_FINAL_DIAMOND = "ADD_FINAL_DIAMOND"
export const UPDATE_DIAMOND = "UPDATE_DIAMOND"
export const UPDATE_DIAMOND_DATA = "UPDATE_DIAMOND_DATA"
export const TOGGLE_BUILD_YOUR_OWN = "TOGGLE_BUILD_YOUR_OWN"
export const DIAMOND_GOTO_STEP = "DIAMOND_GOTO_STEP"
export const DIAMOND_NEXT_STEP = "DIAMOND_NEXT_STEP"
export const DIAMOND_PREV_STEP = "DIAMOND_PREV_STEP"
export const DIAMOND_START_OVER = "DIAMOND_START_OVER"
export const UPDATE_TMP_DIAMOND = "UPDATE_TMP_DIAMOND"
export const ADD_CHOOSE_STONETYPE_STEP = "ADD_CHOOSE_STONETYPE_STEP"
export const REMOVE_CHOOSE_STONETYPE_STEP = "REMOVE_CHOOSE_STONETYPE_STEP"
export const ADD_CHOOSE_GEMSTONE_STEP = "ADD_CHOOSE_GEMSTONE_STEP"
export const REMOVE_CHOOSE_GEMSTONE_STEP = "REMOVE_CHOOSE_GEMSTONE_STEP"

export const SUBSCRIBE_NOTIFICATION = "SUBSCRIBE_NOTIFICATION"

export function updateDisplayState(pref, data) {
  return { data, pref, type: UPDATE_DISPLAY_STATE }
}

export function updateProductSpecs(specs) {
  return { specs, type: UPDATE_PRODUCT_SPECS }
}

export function getShipEstimate(values, actions = {}) {
  return {
    actions,
    type: GET_SHIP_ESTIMATE,
    values,
  }
}

export function addToCart() {
  return { type: ADD_TO_CART }
}

export function cancelAddToCart() {
  return { type: CANCEL_ADD_TO_CART }
}

export function clearDefaultEngravings() {
  return { type: CLEAR_DEFAULT_ENGRAVINGS }
}

export function clearEngravingPos(pref) {
  return { pref, type: CLEAR_ENGRAVING_POS }
}

export function togglePanelIndex(panel, prefOf = "") {
  return { panel, prefOf, type: TOGGLE_PANEL_INDEX }
}

export function forcePanelIndex(panel, prefOf = "") {
  return { panel, prefOf, type: FORCE_PANEL_INDEX }
}

export function scrollPanelIndex(panel) {
  return { panel, type: SCROLL_PANEL_INDEX }
}

export function setModalPanelIndex(panel, callback) {
  return { callback, panel, type: SET_MODAL_PANEL_INDEX }
}

export function hasOpenedPanel(panel, prefOf = "") {
  return { panel, prefOf, type: HAS_OPENED_PANEL }
}

export function showShareEmailModal(data) {
  return { data, type: SHOW_SHARE_EMAIL_MODAL }
}

export function flipImage() {
  return { type: FLIP_IMAGE }
}

export function updateImages(data) {
  return { data, type: UPDATE_IMAGES }
}

export function uploadUserImage(
  pref,
  fileSource,
  isAdmin,
  uploadCallback,
  isHandwriting
) {
  return {
    fileSource,
    isAdmin,
    isHandwriting,
    pref,
    type: UPLOAD_USER_IMAGE,
    uploadCallback,
  }
}

export function cropUploadUserImage(pref, cropper, options = {}) {
  return { cropper, options, pref, type: CROP_UPLOAD_USER_IMAGE }
}

export function editUserImage(pref) {
  return { pref, type: EDIT_USER_IMAGE }
}

export function trackSlide(index) {
  return { index, type: TRACK_SLIDE }
}

export function showMiniview(toggle) {
  return { toggle, type: SHOW_MINIVIEW }
}

export function forceHideMiniview(hideType, toggle) {
  return { hideType, toggle, type: FORCE_HIDE_MINIVIEW }
}

export function toggleMiniviewTab(toggle) {
  return { toggle, type: TOGGLE_MINIVIEW_TAB }
}

export function loadDefaults(pds) {
  return { pds, type: LOAD_DEFAULTS }
}

export function addWizardData(data) {
  return { data, type: ADD_WIZARD_DATA }
}

export function setWizardData(data) {
  return { data, type: SET_WIZARD_DATA }
}

export function setWizardPrefOrder(order) {
  return { order, type: SET_WIZARD_PREF_ORDER }
}

export function setExtraWizardParams(params) {
  return { params, type: SET_EXTRA_WIZARD_PARAMS }
}

/**
 * Triggers loadWizard saga and updates `state.wizard.adminMode`
 * @param {object} Style code and admin mode for wizard loading
 * @option styleCode {string} required
 * @option adminMode {bool} required
 */
export function loadWizard(data) {
  return { data, type: LOAD_WIZARD }
}

export function clearEditFormUrl(data) {
  return { data, type: CLEAR_EDIT_FORM_URL }
}

export function addCoupon(data) {
  return { data, type: ADD_COUPON }
}

export function showError(pref, error_text) {
  return { error_text, pref, type: SHOW_ERROR }
}

export function clearChoices() {
  return { type: CLEAR_CHOICES }
}

export function updateChoice(pref, data, fromUser = false) {
  // If you need to track down what is setting an updateChoice to an
  // undesirable value, uncomment this code and set the pref conditional
  // to whatever you're trying to find
  // if (pref == "s1") {
  //   try {
  //     throw new Error("CAUGHT UPDATECHOICE FOR PREF")
  //   } catch (e) {
  //     console.log(e, pref, data)
  //   }
  // }
  return { data, fromUser, pref, type: UPDATE_CHOICE }
}

export function updateChoiceDirectly(pref, data, fromUser = false) {
  return { data, fromUser, pref, type: UPDATE_CHOICE_DIRECTLY }
}

/**
 * Remove pref from `state.choices`
 */
export function removeChoice(pref) {
  return { pref, type: REMOVE_CHOICE }
}

export function toggleAddonChoice(styleCode) {
  return { styleCode, type: TOGGLE_ADDON_CHOICE }
}

export function clearDiamondTmpData() {
  return { type: CLEAR_DIAMOND_TMP_DATA }
}

export function updatePricing(data) {
  return { data, type: UPDATE_PRICING }
}

export function updateShipping(data) {
  return { data, type: UPDATE_SHIPPING }
}

export function updateShipEstView(view) {
  return { type: UPDATE_SHIP_EST_VIEW, view }
}

export const ACTIVATE_PREF_STORE = "ACTIVATE_PREF_STORE"
export const DEACTIVATE_PREF_STORE = "DEACTIVATE_PREF_STORE"

export function activatePrefStore() {
  return { type: ACTIVATE_PREF_STORE }
}

export function deactivatePrefStore() {
  return { type: DEACTIVATE_PREF_STORE }
}

export const SET_SIGNATURE_BASKET = "SET_SIGNATURE_BASKET"
export function setSignatureBasket(toggle) {
  return { toggle, type: SET_SIGNATURE_BASKET }
}

export function setBuilderItems(cc, params) {
  return { cc, params, type: SET_BUILDER_ITEMS }
}

export function addBuilderItem(style_code) {
  return { style_code, type: ADD_BUILDER_ITEM }
}

export function removeBuilderItem(pref) {
  return { pref, type: REMOVE_BUILDER_ITEM }
}

export function moveBuilderItem(pref, moveDirection) {
  return { moveDirection, pref, type: MOVE_BUILDER_ITEM }
}

export function finishAddBuilderItem() {
  return { type: FINISH_ADD_BUILDER_ITEM }
}

export function setExtraWizardPrefOrder(pref, order) {
  return { order, pref, type: SET_EXTRA_WIZARD_PREF_ORDER }
}

/**
 * Trigger fetch-inventory saga
 * @param {object} options - params options
 * @param {bool} options.get_all_sizes - flag to fetch all sizes
 */
export function fetchInventory(options = {}) {
  return { options, type: FETCH_INVENTORY }
}

/**
 * Trigger fetch-warranty-offers saga
 * @param {object} price - pdp display price
 */
export function fetchWarrantyOffers(price) {
  return { price, type: FETCH_WARRANTY_OFFERS }
}

/**
 * Triggers fetchStylecode saga
 * @param {string} product style code to be fetched
 */
export function fetchStylecode(style_code, skip_add_choices = false) {
  return { skip_add_choices, style_code, type: FETCH_STYLECODE }
}

export function updateInventory(data) {
  return { data, type: UPDATE_INVENTORY }
}

/**
 * Triggers checkAvailability saga
 * @param {object} state object
 */
export function checkAvailability(data) {
  return { data, type: CHECK_AVAILABILITY }
}

export function setPhotoBoxViewIndex(index) {
  return { index, type: PHOTO_BOX_INITIAL_VIEW }
}
export function replaceWizardData(data) {
  return {
    data,
    type: REPLACE_WIZARD_DATA,
  }
}

/**
 * Set wizard loading status
 * @param {"full" | "partial" | ""} loading - set wizard loading status. "full" = full page loading. "partial" = only image is loading
 */
export function setWizardLoading(loading) {
  return { loading, type: SET_WIZARD_LOADING }
}

export function productNotFound() {
  return { type: PRODUCT_NOT_FOUND }
}

export function productFound() {
  return { type: PRODUCT_FOUND }
}

export function setWizardHeader(header) {
  return {
    header,
    type: SET_WIZARD_HEADER,
  }
}

export function resetPaneFilters(data) {
  return { data, type: RESET_PANE_FILTERS }
}

/**
 * Turn on/off V3D button
 */
export function toggleJ3DModal(toggle) {
  return { toggle: toggle, type: TOGGLE_J3D_MODAL }
}
export function toggleJ3DV2Modal(toggle) {
  return { toggle: toggle, type: TOGGLE_J3D_V2_MODAL }
}

export function setBoxDesigns(boxDesigns) {
  return { boxDesigns, type: SET_BOX_DESIGNS }
}

/**
 * Update state.diamondSelector with data
 */
export function updateDiamondData(data) {
  return { data, type: UPDATE_DIAMOND_DATA }
}
/**
 * Set additional steps to choose 4c's when toggle true
 */
export function toggleBuildYourOwn(toggle) {
  return { toggle, type: TOGGLE_BUILD_YOUR_OWN }
}

/**
 * Triggers updateDiamond saga
 */
export function updateDiamond(pref, data) {
  return { data, pref, type: UPDATE_DIAMOND }
}
/**
 * Triggers addFinalDiamond saga
 */
export function addFinalDiamond(diamond) {
  return { diamond, type: ADD_FINAL_DIAMOND }
}

/**
 * Sets state.diamondUI.currentStep to index
 */
export function diamondGoToStep(step) {
  return { step, type: DIAMOND_GOTO_STEP }
}
/**
 * Adds to state.diamondUI.currentStep
 */
export function diamondNextStep() {
  return { type: DIAMOND_NEXT_STEP }
}

/**
 * Removes from state.diamondUI.currentStep
 */
export function diamondPrevStep() {
  return { type: DIAMOND_PREV_STEP }
}
/**
 * Resets state.diamondUI.currentStep to 0
 * Removes state.diamondSelector.selected_diamond and state.diamondUI.tmpDiamond
 */
export function diamondStartOver() {
  return { type: DIAMOND_START_OVER }
}
/**
 * Remove `pref` from state.diamondUI.tmpDiamond
 */
export function updateTmpDiamond(data, getPreviewImage = true) {
  return { data, getPreviewImage, type: UPDATE_TMP_DIAMOND }
}

export function addChooseStonetypeStep() {
  return { type: ADD_CHOOSE_STONETYPE_STEP }
}
export function removeChooseStonetypeStep() {
  return { type: REMOVE_CHOOSE_STONETYPE_STEP }
}
export function addChooseGemstoneStep() {
  return { type: ADD_CHOOSE_GEMSTONE_STEP }
}
export function removeChooseGemstoneStep() {
  return { type: REMOVE_CHOOSE_GEMSTONE_STEP }
}

export function subscribeNotification(email, actions) {
  return { actions, email, type: SUBSCRIBE_NOTIFICATION }
}
