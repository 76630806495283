import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Provider } from "react-redux"

import { configureStore, history, store } from "app-store-v2"
import { doLogin } from "app-store-v2/app/actions"
import { experimentInitialState } from "app-store-v2/app/reducers"
import {
  previewImageInitialState,
  wizardInitialState,
} from "areas/product/store/reducers"
import { setLocalStorage } from "helpers/application"

if (typeof window !== "undefined") {
  window.appStore = store
  window.appStoreV2 = store
  window.appHistory = history
}

const AppReduxProvider = (props) => {
  const { children } = props
  const {
    cart,
    collection_data,
    diamond_selector,
    display,
    experiment_result,
    landing_data,
    previewImage,
    sale,
    search_suggestion,
    user_obj,
    user_preferences,
    wishlist,
    wizard,
  } = props
  let newStoreData = {
    cart,
    display: { ...display },
    previewImage: { ...previewImageInitialState, ...previewImage },
    sale,
    searchSuggestion: {
      name: search_suggestion?.name,
      ...search_suggestion?.data,
    },
    wishlist,
    wizard: { ...wizardInitialState, ...wizard },
  }

  if (collection_data) {
    newStoreData.collection = collection_data
  }

  if (landing_data) {
    newStoreData.landing = landing_data
  }

  if (wizard && wizard.pds) {
    newStoreData.choices = wizard.pds
  }

  if (user_preferences) {
    newStoreData.account = {
      userPreferences: {
        data: user_preferences,
        loaded: true,
      },
    }
  }

  if (diamond_selector) {
    newStoreData.diamondSelector = diamond_selector
  }

  if (experiment_result) {
    newStoreData.experiment = experimentInitialState(history)
    if (experiment_result.free_shipping) {
      newStoreData.experiment = {
        ...newStoreData.experiment,
        freeShipping: experiment_result.free_shipping,
      }
    }
    if (experiment_result.upgraded_shipping) {
      newStoreData.experiment = {
        ...newStoreData.experiment,
        upgradedShipping: experiment_result.upgraded_shipping,
      }
    }
    if (experiment_result.pdp_return_policy) {
      newStoreData.experiment = {
        ...newStoreData.experiment,
        pdpReturnPolicy: experiment_result.pdp_return_policy,
      }
    }

    // Expose product pricing experiences to the Redux store
    const ppt_experiments = {}
    Object.keys(experiment_result)
      .filter((key) => key.startsWith("PPT_"))
      .forEach((experiment_key) => {
        ppt_experiments[experiment_key] = experiment_result[experiment_key]
      })
    if (Object.keys(ppt_experiments).length > 0) {
      newStoreData.experiment = {
        ...newStoreData.experiment,
        ...ppt_experiments,
      }
    }
  }

  const newStore = configureStore(newStoreData)

  if (typeof window !== "undefined") {
    window.appStore = newStore
    window.appStoreV2 = newStore
    window.appHistory = history

    // Loads previous collection pages if users lands on a page number greater than 1
    if (collection_data?.pagination.current_page_number > 1) {
      newStore.dispatch({
        data: {
          url_path: collection_data.mountedPath,
        },
        type: "LOAD_REACT_COLLECTION_SSR",
      })
    }
  }

  useEffect(() => {
    if (user_obj && user_obj != "") {
      // HACKY but this will have to do for now
      // its complementing check is located in the fetch-user.jsx HOC
      window.loginStatus = true
      setLocalStorage(user_obj.jewlrorders.user, "user_obj", 365)
      newStore.dispatch(doLogin(user_obj))
    }
  }, [])

  useEffect(() => {
    if (experiment_result) {
      // Trigger impression
    }
  }, [])

  return <Provider store={newStore}>{children}</Provider>
}

AppReduxProvider.propTypes = {
  cache_key: PropTypes.string,
  cart: PropTypes.object,
  children: PropTypes.element,
  collection_data: PropTypes.object,
  collection_path: PropTypes.string,
  diamond_selector: PropTypes.object,
  display: PropTypes.object,
  experiment_result: PropTypes.object,
  landing_data: PropTypes.object,
  previewImage: PropTypes.object,
  sale: PropTypes.object,
  search_suggestion: PropTypes.object,
  user_obj: PropTypes.object,
  user_preferences: PropTypes.object,
  wishlist: PropTypes.object,
  wizard: PropTypes.object,
}

export default AppReduxProvider
