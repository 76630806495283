import { replace } from "connected-react-router"
import { put, select, all } from "redux-saga/effects"
import URI from "urijs"

import filterMatch from "helpers/product/filter-match"
import {
  checkMetalExists,
  findMatchesInFilters,
  filterProductOptionsV2,
} from "helpers/product/product-filtering"
import { storePref } from "helpers/user-prefs"

import {
  addWizardData,
  setWizardData,
  updateChoice,
  updateChoiceDirectly,
} from "../actions"

export default function* filterChoices(action, directCall = false) {
  try {
    const state = yield select()
    if (!state.wizardUI.prefStore) {
      return
    }

    // Re-enable the choice if it doesn't explicitly keep things disabled UNLESS we aren't in storePref mode yet
    if (
      action.data &&
      state.choices[action.pref] &&
      state.choices[action.pref].disabled &&
      !action.data.disabled &&
      !directCall
    ) {
      let newData = action.data
      newData.disabled = false
      yield put(updateChoice(action.pref, newData))
      // Another saga will handle the rest! :)
      return
    }
    /**
     * e.g., chain wizard advanced ui, we have chain type & chain code selector
     * we used uiPreRenderValue to record the potential chain code for corresponding wizards filter
     */
    const uiPreRenderChoices = {}
    if (action.pref == "sku") {
      if (state.wizard.data.sz) {
        // Get the applicable size by filter and update it
        const currSz = state.choices.sz
        if (currSz) {
          const newSz = state.wizard.data.sz.options.find((sz) => {
            return (
              sz.value == currSz.value &&
              sz.filters &&
              checkMetalExists(sz.filters.sku, action.data)
            )
          })
          let metalExistInCurrent
          if (currSz.filters && currSz.filters.sku) {
            metalExistInCurrent = checkMetalExists(
              currSz.filters.sku,
              action.data
            )
          }

          if (newSz && !metalExistInCurrent) {
            yield put(updateChoice("sz", newSz))
          }
        }
      }
      if (
        state.wizard.data.a1 ||
        state.wizard.data.shank ||
        state.wizard.data.face1
      ) {
        if (state.wizard.data.shank) {
          const currShank = state.choices.shank
          if (currShank) {
            const newShank = state.wizard.data.shank.options.find(
              (co) =>
                co.filters &&
                checkMetalExists(co.filters.sku, action.data) &&
                co.value == currShank.value
            )
            if (
              newShank &&
              !findMatchesInFilters(newShank.filters, currShank.filters, "sku")
            ) {
              yield put(updateChoice("shank", newShank))
            }
          }
        }
        if (state.wizard.data.a1) {
          const currA1 = state.choices.a1
          if (currA1) {
            const newA1 = state.wizard.data.a1.options.find((addition) => {
              return (
                addition.filters &&
                checkMetalExists(addition.filters.sku, state.choices.sku) &&
                addition.value == currA1.value
              )
            })
            if (
              newA1 &&
              !findMatchesInFilters(newA1.filters, currA1.filters, "sku")
            ) {
              yield put(updateChoice("a1", newA1))
            }
          }
        }

        var i = 1
        while (state.wizard.data["face" + i]) {
          const face = "face" + i
          const currF = state.choices[face]

          if (currF) {
            const options = state.wizard.data[face].options.filter((fv) => {
              // return !fv.filter || fv.filter == metalFilter
              return (
                !fv.filters ||
                checkMetalExists(fv.filters.sku, state.choices.sku)
              )
            })

            const newF = options.find((fv) => {
              return fv.value == currF.value
            })

            if (newF) {
              yield put(updateChoice(face, newF))
            } else if (options.length > 0) {
              yield put(updateChoice(face, options[0]))
            }
          }
          i += 1
        }
      }

      const update_chain_choice_arr = []
      const chains = ["ch", "ch2"]
      chains.forEach((ch) => {
        if (state.wizard.data[ch]) {
          const currCh = state.choices[ch]
          const chainOptions = filterProductOptionsV2(
            state.wizard.data[ch].options,
            state.choices,
            true
          )
          const newCh = chainOptions.find((chain) => {
            return (
              checkMetalExists(chain.filters.sku, state.choices.sku) &&
              chain.chain_type == (currCh ? currCh.chain_type : "") &&
              chain.chain_length == (currCh ? currCh.chain_length : "") &&
              chain.extender == (currCh ? currCh.extender : false)
            )
          })
          if (newCh) {
            update_chain_choice_arr.push(put(updateChoice(ch, newCh)))
          } else {
            // Special case - chains often don't match so we clear them out
            update_chain_choice_arr.push(
              put(
                updateChoice(ch, {
                  chain_type: "",
                  display_price: null,
                  imageKey: "",
                  price: 0,
                  title: null,
                  value: "",
                })
              )
            )
          }
        }
      })
      yield all(update_chain_choice_arr)
    } else if (action.pref == "sz" && state.wizard.data.a1) {
      const currOpt2 = state.choices.a1
      const newOpt2 = state.wizard.data.a1.options.find((addition) => {
        return (
          (!addition.size_filter ||
            addition.size_filter == "" ||
            (state.choices.sz &&
              addition.size_filter == state.choices.sz.value)) &&
          currOpt2 &&
          addition.value == currOpt2.value &&
          (!addition.filters ||
            !addition.filters.sku ||
            addition.filters.sku
              .map((i) => i.value)
              .includes(state.choices.sku.value))
        )
      })
      if (newOpt2) {
        yield put(updateChoice("a1", newOpt2))
      }
    } else if (action.pref == "sc") {
      let num_stones = action.data.value
      let min_stones = state.wizard.data.sc.min_stones
      let max_stones = state.wizard.data.sc.max_stones

      for (let i = Math.max(1, min_stones); i <= parseInt(num_stones); i++) {
        yield put(updateChoice("s" + i, { disabled: false }))
      }
      for (let i = parseInt(num_stones) + 1; i <= max_stones; i++) {
        yield put(updateChoice("s" + i, { disabled: true }))
      }

      if (
        state.wizard.data.ef &&
        state.wizard.data.ef.implicit_engraving_count
      ) {
        yield put(updateChoice("ec", { value: parseInt(num_stones) }))
      }
    } else if (action.pref == "ec") {
      let num_engravings = action.data.value
      let max_engravings = state.wizard.data.ef.max_engraving_count

      for (let i = 1; i <= num_engravings; i++) {
        yield put(updateChoice("e" + i, { disabled: false }))
      }
      for (let i = parseInt(num_engravings) + 1; i <= max_engravings; i++) {
        yield put(updateChoice("e" + i, { disabled: true }))
      }
    } else if (action.pref === "o1") {
      const chainData = state.wizard.data["ch"]
      if (chainData) {
        const chainOptions = filterProductOptionsV2(
          chainData.options,
          state.choices,
          true
        )
        let chainTypeMatch = false
        let match = false
        chainOptions.forEach((chain) => {
          if (state.choices["ch"]?.chain_type === chain?.chain_type)
            chainTypeMatch = true
          if (state.choices["ch"]?.value === chain?.value) match = true
        })
        if (!match) {
          yield put(
            updateChoice("ch", {
              chain_type: chainTypeMatch ? state.choices["ch"]?.chain_type : "",
              display_price: null,
              imageKey: "",
              price: 0,
              title: null,
              value: "",
            })
          )
        }
      }
    } else if (action.pref === "ef" || action.pref === "ef2") {
      // we use filters on engraving fonts, e.g., JADD0004-CREAM
      // update the default choice option, if font isn't found after filtered
      const fontData = state.wizard.data[action.pref]
      if (fontData && !fontData.disabled) {
        const choices = state.choices
        const fontOptions = filterProductOptionsV2(
          fontData.options,
          choices,
          true
        )
        let match = false
        fontOptions.forEach((font) => {
          if (state.choices[action.pref].value === font.value) match = true
        })
        if (!match && fontOptions.length) {
          yield put(updateChoiceDirectly(action.pref, fontOptions[0]))
        }
      }
    } else if (
      action.data &&
      typeof action.data.num_outside_engravings === "number"
    ) {
      let num_engravings = action.data.num_outside_engravings
      for (let i = 0; i < state.wizard.data.ef.engravings.length; i++) {
        const engraving = state.wizard.data.ef.engravings[i]
        if (engraving.engraving_num != 0) {
          let engravingData = engraving
          if (engraving.engraving_num <= num_engravings) {
            engravingData.disabled = false
          } else {
            engravingData.disabled = true
          }
          yield put(addWizardData(engravingData))
        }
      }
    } else if (
      (action.pref === "ch" || action.pref === "ch2") &&
      !action.data.disabled &&
      action.data.value === ""
    ) {
      const wizardData = state.wizard.data[action.pref]
      const chainTypes = filterProductOptionsV2(
        wizardData.types,
        state.choices,
        true
      )
      let defaultType = chainTypes[0]
      const selectedChainType = action.data.chain_type
      if (selectedChainType) {
        defaultType = chainTypes.find((t) => t.type === selectedChainType)
        if (!defaultType) defaultType = chainTypes[0]
      }
      if (defaultType?.options)
        uiPreRenderChoices[action.pref] = defaultType.options[0]?.value || ""
    } else if (action.pref === "a2") {
      const paneFilters = state.wizard.paneFilters[action.pref] || []
      paneFilters.forEach((pfilter) => {
        const chainFilterKeys = (Object.keys(pfilter.filters) || []).filter(
          (key) => key === "ch" || key === "ch2"
        )
        chainFilterKeys.forEach((chainKey) => {
          if (
            !state.choices[chainKey].disabled &&
            state.choices[chainKey].value === ""
          ) {
            const wizardData = state.wizard.data[chainKey]
            const chainTypes = filterProductOptionsV2(
              wizardData.types,
              state.choices,
              true
            )
            let defaultType = chainTypes[0]
            const selectedChainType = state.choices[chainKey].chain_type
            if (selectedChainType) {
              defaultType = chainTypes.find((t) => t.type === selectedChainType)
              if (!defaultType) defaultType = chainTypes[0]
            }
            if (defaultType?.options)
              uiPreRenderChoices[chainKey] = defaultType.options[0]?.value || ""
          }
        })
      })
    }
    let updates = []
    if (state.wizard.paneFilters[action.pref]) {
      let fkey = action.pref

      var paneFilters = state.wizard.paneFilters[fkey] || []
      let showKeys = []

      const validateChoices = JSON.parse(JSON.stringify(state.choices))
      for (const [key, value] of Object.entries(uiPreRenderChoices)) {
        if (validateChoices[key]) validateChoices[key].value = value
      }

      paneFilters.forEach((pfilter) => {
        // in recapture data - filterMatch
        pfilter.uiPreRenderChoices = uiPreRenderChoices
        if (Object.keys(pfilter.filters).length > 0) {
          const subFilterKeys = Object.keys(pfilter.filters)
          // Check all other sub filters for an AND match on the rest of the chosen options
          const validKeys = subFilterKeys.filter(
            (sfkey) =>
              !!pfilter.filters[sfkey].find(
                (r) =>
                  state.choices[sfkey] &&
                  filterMatch(r, validateChoices[sfkey].value)
              )
          )
          if (subFilterKeys.length == validKeys.length) {
            updates.push(put(setWizardData(pfilter)))
            showKeys.push(pfilter.pref)
          }
        } else {
          updates.push(put(setWizardData(pfilter)))
          showKeys.push(pfilter.pref)
        }
      })

      // Find any panes, not in showKeys, that DON'T belong, only when there is an active value
      if (action.data.value === "" || action.data.value) {
        Object.keys(state.wizard.data).forEach((pkey) => {
          if (typeof state.wizard.data[pkey].filters !== "undefined") {
            const filters = state.wizard.data[pkey].filters

            let checkFilterMatch = true
            if (typeof filters[action.pref] !== "undefined") {
              const pKeyFilters = Object.keys(filters)
              for (let i = 0; i < pKeyFilters.length; i++) {
                const key = pKeyFilters[i]
                const filterArr = filters[key]
                const value =
                  key == action.pref
                    ? action.data.value
                    : state.choices[key]?.value
                checkFilterMatch = filterArr.some((r) => filterMatch(r, value))
                if (!checkFilterMatch) break
              }
            }

            if (
              showKeys.indexOf(pkey) === -1 &&
              typeof filters[action.pref] !== "undefined" &&
              !action.data.skipDisablePref &&
              !checkFilterMatch
            ) {
              if (state.wizard.data[pkey].disabled) return
              updates.push(put(setWizardData({ disabled: true, pref: pkey })))
              // updates.push(put(updateChoice(pkey, {disabled: true})))
            } else {
              if (!state.choices[pkey]?.disabled) return
              updates.push(put(updateChoice(pkey, { disabled: false })))
            }
          } else if (
            state.wizard.data[pkey].disabled &&
            showKeys.indexOf(pkey) === -1
          ) {
            if (state.choices[pkey]?.disabled) return
            // Ensure that choice remains disabled
            updates.push(put(updateChoice(pkey, { disabled: true })))
          }
        })
      }
    }

    Object.keys(state.wizard.filters).forEach((fkey) => {
      const filterKeys = state.wizard.filters[fkey]
      if (filterKeys.indexOf(action.pref) !== -1) {
        // Get the new applicable option by filter and update it
        const currOpt = state.choices[fkey]
        if (currOpt) {
          const newOpts = state.wizard.data[fkey].options
            ? state.wizard.data[fkey].options.filter(
                (opt) =>
                  //Filter by array of allowed values or regex
                  filterKeys.filter(
                    (fkey2) =>
                      typeof opt.filters !== "undefined" &&
                      (typeof opt.filters[fkey2] === "undefined" ||
                        opt.filters[fkey2].find((r) =>
                          filterMatch(
                            r,
                            state.choices && state.choices[fkey2]
                              ? state.choices[fkey2].value
                              : ""
                          )
                        ))
                  ).length == filterKeys.length
              )
            : []

          const newOpt = newOpts.find((opt) => opt.value == currOpt.value)
          // skip reselecting for filtered multi-sku switcher to avoid infinite loop
          if (
            newOpt &&
            state.wizard.data[action.pref] &&
            state.wizard.data[action.pref].ui_type !== "stylecode"
          ) {
            updates.push(put(updateChoice(fkey, newOpt)))
          } else if (fkey[0] == "m" && typeof currOpt.value !== "undefined") {
            // Attempt to find an alternate metal of the same colour
            const altOpt = newOpts.find(
              (opt) =>
                opt.value[opt.value.length - 1] ==
                currOpt.value[currOpt.value.length - 1]
            )
            if (altOpt) {
              updates.push(put(updateChoice(fkey, altOpt)))
            } else {
              updates.push(put(updateChoice(fkey, newOpts[0])))
            }
          } else if (newOpts.length > 0) {
            // I don't think we need this. It ends up setting a blank ring size to the smallest size
            // which is highly undesirable
            // updates.push(put(updateChoice(fkey, newOpts[0])))
          }
        }
      }
    })
    yield all(updates)
    const updatedState = yield select()
    if (
      updatedState.wizardUI.prefStore &&
      action.data &&
      (action.data.value || action.data.value === "") &&
      action.data.value !=
        (updatedState.choices[action.pref]
          ? updatedState.choices[action.pref].lastValue
          : null) &&
      !action.data.is_default &&
      !directCall &&
      action.fromUser
    ) {
      const relatedData = updatedState.wizard.data[action.pref]
      if (
        typeof relatedData === "undefined" ||
        !["Arabic", "Hebrew"].includes(relatedData.caps)
      ) {
        storePref(
          action.pref,
          action.data.value,
          updatedState.choices.style_code?.value
        )
      }
      // Update the SEO path, query string, and SEO product title
      const { pathname, search, hash } = updatedState.router.location
      let uri = URI(`${pathname}${search}${hash}`)
      let query = uri.search(true)
      if (action.pref !== "gift" && action.data.value) {
        query[action.pref] = action.data.value
      }
      uri.search(query)
      try {
        yield put(replace(uri.toString()))
      } catch (e) {
        // Ignore Safari's history.replaceState() rate limit error.
        // See https://github.com/nusmodifications/nusmods/issues/763
        if (
          e.name === "SecurityError" &&
          e.message.includes("Attempt to use history.replaceState()")
        ) {
          return
        }
        // Continue throwing all other errors
        throw e
      }
    }
  } catch (err) {
    console.error("FILTER CHOICES ERROR", err)
  }
}
