import { mediaStyle } from "@jewlr/storybook/core"
import { createGlobalStyle } from "styled-components"

import normalize from "styles/normalize"

const fontFace = (fontFamily, src, fontWeight, fontStyle) => {
  return `
    @font-face {
      font-family: '${fontFamily}';
      src: url('https://assets.jewlr.com/fonts/${src}.woff2') format('woff2'),
           url('https://assets.jewlr.com/fonts/${src}.woff') format('woff'),
           url('https://assets.jewlr.com/fonts/${src}.ttf') format('truetype'),
           local("Helvetica"), local("Arial");
      font-weight: ${fontWeight};
      font-style: ${fontStyle};
      font-display: swap;
      ascent-override: 100%;
    }
  `
}

export const GlobalStyle = createGlobalStyle`
  ${fontFace("Mier B", "mier-b/mier-b-regular", "normal", "normal")};
  ${fontFace("Mier B", "mier-b/mier-b-book", "500", "normal")};
  ${fontFace("Mier B", "mier-b/mier-b-book-italic", "500", "italic")};
  ${fontFace("Mier B", "mier-b/mier-b-demi-bold", "600", "normal")};
  ${fontFace("Mier B", "mier-b/mier-b-bold", "bold", "normal")};
  ${fontFace("Mier B", "mier-b/mier-b-demi-bold-italic", "600", "italic")};
  ${fontFace("Mier B", "mier-b/mier-b-italic", "normal", "italic")};
  ${fontFace("Mier B", "mier-b/mier-b-bold-italic", "bold", "italic")};
  @font-face {
    font-family: 'Mier B Fallback';
    src: local("Arial");
    ascent-override: 77.00%;
    descent-override: 25.67%;
    line-gap-override: 34.79%;
    size-adjust: 97.40%;
  }
  ${(props) => normalize(props)};
  ${mediaStyle}
`
