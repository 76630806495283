import { put, select } from "redux-saga/effects"

import { IS_US } from "helpers/application"

import { checkAvailability, updatePricing } from "../actions"

export default function* updatePriceAndShippingTime(action) {
  const state = yield select()
  if (!state.wizardUI.prefStore || action.pref === "gift") {
    return
  }

  try {
    var jsum = 0
    var jluxsum = 0
    var diamond_sum = 0
    var jsalesum = 0
    var metal_sum = 0
    // var metal_sale_sum = 0
    // var ships_in = 0
    // Can't find this data prop in code anymore
    // var ships_in = Number(
    //   $(".item_shipping_info span").attr("data-base-ship-days")
    // )
    // if (isNaN(ships_in)) ships_in = 0
    var sku_regular_price = state.choices.sku ? state.choices.sku.price : null
    // var sku_regular_price = $("#sku_description_input").data('price');
    if (sku_regular_price) {
      // var sku_sale_price = $("#sku_description_input").data('sale-price');
      var sku_sale_price = state.choices.sku.sale_price
      if (sku_sale_price !== undefined && sku_sale_price !== null)
        jsalesum += Number(sku_sale_price)
      else jsalesum += Number(sku_regular_price)
      jsum += Number(sku_regular_price)
      jluxsum += Number(sku_regular_price)
    }
    metal_sum = jsum
    // metal_sale_sum = jsalesum
    Object.keys(state.choices).forEach((key) => {
      const choice = state.choices[key]
      if (
        choice.disabled ||
        key == "sku" ||
        key == "box" ||
        choice.is_default
      ) {
        return
      }
      var data_price = choice.price
      if (data_price !== undefined) {
        var sale_price = choice.sale_price
        if (sale_price !== undefined && sale_price !== null) {
          jsalesum += Number(sale_price)
        } else {
          jsalesum += Number(data_price)
        }
        jsum += Number(data_price)
        if (key != "s1") {
          jluxsum += Number(data_price)
        }
      }
      var sia = choice.sia
      if (sia !== undefined) {
        // ships_in += Number(sia)
      }
    })

    if (state.diamondSelector.selected_diamond?.price) {
      diamond_sum = state.diamondSelector.selected_diamond.price
    }

    var item_on_sale = jsum != jsalesum

    var surcharge_sum = jsum - metal_sum - diamond_sum
    // var surcharge_sale_sum = jsalesum - metal_sale_sum
    // var sku_metal = state.choices.sku ? state.choices.sku.value : null // $("#sku_description_input").val();
    var retail_markup = state.wizard.price_and_promo.rf
    var diamond_markup = 1 / 0.8

    var rsum =
      metal_sum * retail_markup +
      diamond_sum * diamond_markup +
      surcharge_sum * retail_markup

    // var display_retail_percent = ((rsum - jsum) / rsum) * 100
    // if ( style_code.indexOf("JWLN") == 0)
    //   rsum = jsum + parseFloat( $("#sku_description_input").data('price') ) + 1;

    var tax_rate = 1.0
    var precision = 0 //tax_rate > 1.0 ? 2 : 0;

    // // Promo Code application
    // if (adjust < 1) {
    //   jsalesum = jsum * adjust;
    //   precision = 2;
    //   if (!init_prices) {
    //     $("#summary_price, #selling_price").after('<div id="original_price"><span class="orig_price_fields"></span> <span class="retail-price"></span></div>');
    //     $(".summary-footer-price").html('<span class="savings">SAVE '+promo_amount+'% UNTIL MONDAY, JUNE 22ND!</span>');
    //     $("#savings").html('<span class="sale-label">SAVE '+promo_amount+'% UNTIL MONDAY, JUNE 22ND!</span><br/>');
    //     init_prices = true;
    //   }
    //   $(".price_fields").css("display", "inline-block");
    //   $(".sales_savings").hide();
    //   $(".savings-label").hide();
    //   $(".savings-amount").hide();
    //   $("#wizard_mobile_add_to_cart").css("padding-top", 0);
    //   //$(".summary-footer-price").css("margin-top", 0);
    //   $(".summary-footer-price").hide();
    //   $(".value-add-box").css("margin-top", "2px");
    //   $("#item_promo_info").css("margin-top", "4px").css("padding-top", 0);
    // }

    var price_to_display = (jsum == jsalesum ? jsum : jsalesum) * tax_rate
    var jlux_price_to_display = jluxsum * tax_rate
    var regular_price = jsum * tax_rate
    var onsale_price = jsalesum * tax_rate

    var retail_price = rsum * tax_rate
    var savings = (rsum - jsalesum) * tax_rate

    // New calculation
    if (jsum == jsalesum) {
      savings = retail_price - regular_price
    } else {
      //savings = (regular_price - onsale_price);
      savings = retail_price - onsale_price
    }

    let coupon_percent = null
    let coupon_discount_price = false

    if (state.wizard.coupon && state.wizard.coupon.tiered_discount) {
      let tiered_discounts = state.wizard.coupon.tiered_discount
      let discount = 0
      // let discount_tier = 0
      for (let tier in tiered_discounts) {
        if (price_to_display > Number(tier)) {
          discount = tiered_discounts[tier]
          // discount_tier = Number(tier)
        }
      }
      coupon_percent = Number(discount) / 100.0
    } else if (state.wizard.coupon && state.wizard.coupon.discount) {
      coupon_percent = state.wizard.coupon.discount / 100.0
    }

    if (state.wizard.coupon && coupon_percent !== null) {
      if (state.diamondSelector.selected_diamond?.price) {
        coupon_discount_price =
          jlux_price_to_display -
          jlux_price_to_display * coupon_percent +
          Number(state.choices.s1.price)
      } else {
        coupon_discount_price =
          price_to_display - price_to_display * coupon_percent
      }
    }

    // $(".orig_price_fields").html(number_to_currency(price_to_display / adjust, {precision: precision}));  //Doesn't exist right now
    //$(".js-regular-price").html(number_to_currency(regular_price, {precision: precision}))                 //Doesn't exist right now
    // $(".onsale_price").html(number_to_currency(jsalesum, {precision: precision}));                        //Doesn't exist right now
    // $('.js-retail-percent').html(Math.round(display_retail_percent) + '%');                               //Doesn't exist right now

    //Moved to react
    //$('.js-coupon-discount').html(number_to_currency(coupon_discount_price, {precision: precision}));     //GOOD
    //$(".js-selling-price").html(number_to_currency(price_to_display, {precision: precision}));            //Mobile view still has one
    //$(".js-retail-price").html(number_to_currency(parseInt(retail_price), {precision: precision}));       //Mobile view still has one
    //var savings_amount = number_to_currency(parseInt(savings), {precision: precision});
    //$(".js-savings-amount").html(savings_amount);                                                         //Mobile view still has one

    let show_affirm = IS_US()

    yield put(
      updatePricing({
        coupon_discount_price,
        precision,
        price_to_display,
        regular_pricing: item_on_sale ? regular_price : null,
        retail_price: Math.round(retail_price),
        savings: parseInt(savings),
        show_affirm,
      })
    )

    // Check for size/sku availability on change
    // if (action.pref && $.inArray(action.pref, ['sku', 'sc', 'chead', 'cshank', 'sz']) > -1) {

    // Checking for all items (not just inventory) b/c of potential for different manufacturing
    // time for rose gold items
    // if (state.wizard.hasInventory) &&

    let hasNoEngravings = false
    // Don't hammer servers with availability
    // Only check availability if no other engravings are entered and current engraving field is either made empty or has a newly entered value
    if (action.pref?.[0] === "e") {
      hasNoEngravings = Object.keys(state.choices)
        .filter(
          (pref) => pref[0] === "e" && pref !== "ef" && pref !== action.pref
        )
        .every((pref) => state.choices[pref].value === "")
    }
    if (
      action.pref?.[0] !== "e" ||
      (hasNoEngravings &&
        (state.choices[action.pref].lastValue === "" ||
          action.data.value === ""))
    ) {
      yield put(checkAvailability(state))
    }

    // Fetch Warranty Offers (if we decide to add to PDP)
    // if (action.pref !== "warranty") {
    //   yield put(fetchWarrantyOffers(price_to_display))
    // }
  } catch (e) {
    console.error(e)
  }
} // end updatePriceAndShippingTime
